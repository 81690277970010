import React from 'react';

const Contact = () => {
    return <div id="outer-about-container" className="outer-body-container">
                <div id="about-container" className="body-container">
                        <h1>Contact Page</h1>
                </div>
    </div>

};
export default Contact;