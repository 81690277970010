import React from 'react';

const About = () => {
    return <div id="outer-glossary-container" className="outer-body-container">

        <div>
            <h1>About Page</h1>
        </div>
        <div id="about-container" className="body-container">

        </div>
    </div>
};

export default About;