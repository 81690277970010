import React from 'react';
import {Link} from "react-router-dom";

const Home = () => {
    return <div className="full-body-container">
                <div className="title-container">
                    <h1 className="poppins-title-italic">The Simulacrum</h1>
                </div>
                <div id="outer-home-container" className="outer-body-container">
                    <div>
                        <img className="body-image" id="home-image" src="images/ImpossibleMachine2.png" alt="Gm Blockchain"/>
                    </div>
                    <div id="home-container" className="body-container">

                        <div>
                            <h3 className="poppins-sub-title-italic">Create</h3>
                            <h3 className="poppins-sub-title-italic">Develop</h3>
                            <h3 className="poppins-sub-title-italic">Educate</h3>
                            <p className="poppins-regular">
                                Simulacrum Images is an NFT Production Lab dedicated to exploring and showcasing NFTs as an art medium and developing potential for creation, connection, education and beyond.
                            </p>
                        </div>


                </div>
                </div>
                    <div className="home-links-container">

                            <div className="home-link-container">
                                <Link to="/gm-blockchain" className="home-links">
                                    <p>GM Block Chain Book</p>
                                </Link>
                            </div>
                        <div className="home-link-container">
                                <Link to="/glossary" className="home-links">
                                    <p>Glossary</p>
                                </Link>
                            </div>
                        <div className="home-link-container">
                                <Link to="/articles" className="home-links">
                                    <p>Articles</p>
                                </Link>
                            </div>
                        <div className="home-link-container">
                                <Link to="/mimesis-castle" className="home-links">
                                    <p>Mimesis Castle</p>
                                </Link>
                            </div>
                        <div className="home-link-container">
                                <Link to="/galleries" className="home-links">
                                    <p>Galleries</p>
                                </Link>
                            </div>
                        <div className="home-link-container">
                                <Link to="/services" className="home-links">
                                    <p>Services</p>
                                </Link>
                            </div>
                        {/*<div className="home-link-container">*/}
                        {/*        <a href='https://explorer.simulacrumimages.com/' className="home-links" target="_blank" rel="noopener noreferrer">*/}
                        {/*            <p>Explorer</p>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        <div className="home-link-container">
                                <a href='https://muulti.xyz/' className="home-links" target="_blank" rel="noopener noreferrer">
                                    <p>Muulti</p>
                                </a>
                                    <p>Token Gated Video Platform - Create token gated video playlists.</p>

                            </div>
                        </div>


    </div>
};

export default Home;