import React from 'react';

const Services = () => {
    return <div id="outer-gallery-container" className="outer-body-container">
                <div id="about-container" className="body-container">
                    <h1 className="poppins-sub-title-italic">Services</h1>
                    <h3 className="poppins-sub-title-italic">Education</h3>
                    <p className="poppins-regular">Education on web3 and blockchain technology.</p>
                    <h3 className="poppins-sub-title-italic">NFT Production</h3>
                    <p className="poppins-regular">Experimentation, creation and presentation of various nft projects</p>
                    <h3 className="poppins-sub-title-italic">Web3 Development</h3>
                    <p className="poppins-regular">Development of frontend web3 applications.</p>
                </div>
            </div>
};

export default Services;