import React from 'react';

const GoodMorningBlockChainBook = () => {
    return <div id="outer-book-container" className="outer-body-container">
        <div id="book-image-container" className="body-image-container">
            <img className="body-image" id="gm-blockchain-image" src='/images/GmBlockchain/GmSolanaBlack.jpg' alt="Gm Blockchain"/>
        </div>
                <div id="book-container" className="body-container">
                    <h3 className="poppins-sub-title-italic">GM Solana: An Introduction to Blockchain and NFTs for Artists and Creators</h3>
                            <h3>Description</h3>
                            <p>
                            Description:
                            Gm Solana: Introduction to Blockchain and NFTs for Artists and Creators is a book that teaches the basic concepts that anyone needs to know to be able to navigate through Web3 and the NFTs. This book will provide readers with an introductory overview of NFTs, Web3 and how they are relevant. Readers will be introduced to all the concepts that they need to know to better understand blockchain and NFTs, how they work, where they could fit in the world and why these ideas are important for the ongoing evolution of the internet. Designed by the most awesome NOiSY COYOTE!
                            </p>
                    <p className="poppins-regular">Download the book from Gumroad:</p>
                    <a className="poppins-regular"
                       href='https://nortniluhreg.gumroad.com/l/gm-solana-intro-to-blockchain'
                       target="_blank"
                       rel="noopener noreferrer">Click here to download the book from Gumroad
                    </a>
                    <p  className="poppins-regular">Superteam Earn Solana Scribes Writing Hackathon: Second Place for Getting Started With Solana</p>
                    <a
                        className="poppins-regular"
                        href='https://earn.superteam.fun/listings/hackathon/best-ecosystem-getting-started-guide/submission/166ce26f-97ff-4117-a646-cd0e7dfcc787/'
                        target="_blank"
                        rel="noopener noreferrer">
                        View Page
                    </a>
                    <p className="poppins-regular">
                        Read Gm Blockchain on Medium:
                    </p>
                    <a  className="poppins-regular"
                        href='https://medium.com/@Simulations_SOL/gm-solana-an-introduction-to-blockchain-nfts-and-web3-for-everyone-c275b5c55502'
                        target="_blank"
                        rel="noopener noreferrer">
                        View Page
                    </a>

                </div>
        </div>


};

export default GoodMorningBlockChainBook;