// src/pages/ArticleDetails.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../assets/styles/SimulacrumImagesStyle.css'; // Import the CSS file

const ArticleDetails = () => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/data/simulacrum_images_articles.json');
                const article = response.data.articles.find((article) => article.id === parseInt(id));
                setArticle(article);
            } catch (error) {
                console.error('Error fetching the article details:', error);
            }
        };

        fetchData();
    }, [id]);

    if (!article) {
        return <div>Loading...</div>;
    }

    const renderParagraph = (paragraph, index) => {
        const { type, heading, imageUrl, body } = paragraph;

        switch (type) {
            case 'quote':
                return (
                    <blockquote key={index} className="article-paragraph quote">
                        <p>{body}</p>
                    </blockquote>
                );
            case null:
            default:
                return (
                    <div key={index} className="article-paragraph">
                        {heading && <h3>{heading}</h3>}
                        {imageUrl && <img src={imageUrl} alt={heading || 'Article Image'} />}
                        <p>{body}</p>
                    </div>
                );
        }
    };

    return (
        <div className="article-details-container">
            <h1>{article.title}</h1>
            {article.content.map((paragraph, index) => renderParagraph(paragraph, index))}
            <p>By {article.author} on {new Date(article.date).toLocaleDateString()}</p>
        </div>
    );
};

export default ArticleDetails;
