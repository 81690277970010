import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../assets/styles/SimulacrumImagesStyle.css';

const ArticleList = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/data/simulacrum_images_articles.json');
                setArticles(response.data.articles);
            } catch (error) {
                console.error('Error fetching the articles:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div id="outer-articles-container" className="outer-body-container">
            <div className="body-container">
                <h3  className="poppins-sub-title-italic">Articles</h3>
                <div id="article-list-container">
                    {articles.map((article) => (
                        <div className="article-container" key={article.id}>
                            <Link to={`/articles/${article.id}`}>
                                <h3 className="article-container-title">{article.title}</h3>
                                <p>By {article.author}</p>
                                <p>{new Date(article.date).toLocaleDateString()}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ArticleList;