import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Glossary = () => {
    const [terms, setTerms] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLetter, setSelectedLetter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('data/web3_glossary.json');
                setTerms(response.data.terms);
            } catch (error) {
                console.error('Error fetching the glossary data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setSelectedLetter('');  // Clear the selected letter when a search term is entered
    };

    const handleLetterClick = (letter) => {
        setSelectedLetter(letter);
        setSearchTerm('');  // Clear the search term when a letter is selected
    };

    const filteredTerms = terms.filter(term => {
        if (selectedLetter) {
            return term.term.toLowerCase().startsWith(selectedLetter.toLowerCase());
        }
        return term.term.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    return (
        <div id="outer-glossary-container" className="outer-body-container">
            <div>
                <h3 className="poppins-sub-title-italic">Web3 Glossary</h3>
                <p>
                    Find words related to web3, blockchain, tokens and NFTs.
                </p>
            </div>
            <div className="body-container" id="glossary-container">

                <input
                    type="text"
                    placeholder="Search for a term..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-bar"
                />

                <div className="letter-selection">
                    {alphabet.map((letter) => (
                        <button
                            key={letter}
                            className={`letter-button ${selectedLetter === letter ? 'active' : ''}`}
                            onClick={() => handleLetterClick(letter)}
                        >
                            {letter}
                        </button>
                    ))}
                    <button
                        className={`letter-button ${selectedLetter === '' ? 'active' : ''}`}
                        onClick={() => setSelectedLetter('')}
                    >
                        All
                    </button>
                </div>

                <ul>
                    {filteredTerms.map((term) => (
                        <li key={term.term}>
                            <h2>{term.term}</h2>
                            <p>{term.definition}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Glossary;
