// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SimulacrumImagesStyles from './assets/styles/SimulacrumImagesStyle.css';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import SimulacrumImagesNavbar from "./components/SimulacrumImagesNavbar";
import GoodMorningBlockChainBook from "./pages/GoodMorningBlockChainBook";
import Glossary from "./pages/Glossary";
import ArticleList from "./pages/ArticleList";
import ArticleDetails from "./pages/ArticleDetails";
import MimesisCastlePage from "./pages/MimesisCastlePage";
import posthog from 'posthog-js'
import Galleries from "./pages/Galleries";

posthog.init('phc_XvVYr8Uo4a81pSwXCuYxbca92QGmspc55SyAXO4s4TZ',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
);

function App() {
    return (
        <Router>
            <SimulacrumImagesNavbar />
            <div>
                <Routes className="main-body-container">
                    <Route path="/" element={<Home />} />
                    <Route path="/glossary" element={<Glossary />} />
                    <Route path="/gm-blockchain" element={<GoodMorningBlockChainBook />} />
                    <Route path="/articles" element={<ArticleList />} />
                    <Route path="/articles/:id" element={<ArticleDetails />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/mimesis-castle" element={<MimesisCastlePage />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/galleries" element={<Galleries/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
