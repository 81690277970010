import React from 'react';

const MimesisCastlePage = () => {
    return <div id="outer-mimesis-castle-container" className="outer-body-container">
        <div id="mimesis-castle-image-container" className="body-image-container">
            <img className="body-image" id="mimesis-castle-image" src='/images/MimesisCastlePFP - Copy.png' alt="Gm Blockchain"/>
        </div>
            <div id="home-container" className="body-container">
            <h1 className="poppins-sub-title-italic">Mimesis Castle on DRiP</h1>
            <div>
                <ul>
                    <li>🎭 Welcome to Mimesis Castle, a web3 digital collectible variety show curated by Simulations.</li>
                    <li>🌟 Imagine stepping into a cabaret, but instead of leaving, you collect a piece of the show! Collect a variety of video scenes, music tracks, illustrations, graphics and more every week!</li>
                    <li>📜 Main Collection: Experience short analogue animations, akin to puppet shows, featuring a variety of approaches, scenes and stories. These are all built into various kinds of found objects like suitcases, computer cases and other kinds of space that can be transformed into a new miniature world.</li>
                    <li>🎨 Special Curations: Features that present exceptional works from various artists across different mediums to bring the variety to the show.</li>
                </ul>
                <a href="https://drip.haus/mimesiscastle" target="_blank" rel="noopener noreferrer">Take a look at Mimesis Castle on DRiP</a>
                <a href="https://www.tensor.trade/trade/mimesis_castle_simulations_drip" target="_blank" rel="noopener noreferrer">See the collection on Tensor</a>
                <a href="https://magiceden.io/marketplace/mimesis_drip" target="_blank" rel="noopener noreferrer">See the collection on Magic Eden</a>
            </div>
                <div>

                </div>
        </div>
    </div>
};

export default MimesisCastlePage;